/* styles.css */


:root {
  --c-background: #cbffe8;
  --c-text: #000000;
  --c-topBar: rgb(6, 6, 2);
  --c-introText: #02170e;
  --c-button1: #222222;
  --c-text2: #ffffff;
  --c-accent: #E5258D;
  --c-accent2: #12ec90;
  --c-banner: #290e1c;
  --c-banner: rgb(64, 208, 143);
  --c-slidershaddow: #e5258b8b;
  --c-slidershaddow2: #e5258bbd;
  --c-warning: #700909;
}

.whiteText {
  color: var(--c-text2);
}


h3 {
  padding: 0;
  margin: 0;
}

body,
button,
input {
  font-family: 'Poppins', sans-serif;
}


.container {
  background-image: url('/public/images/pattern.png');
  background-Repeat: 'repeat';
  width: 100%;
}

.mainBackground {
  height: '100vh';
  width: '100vw';
}

.mModuleSelection {
  background-color: var(--c-background);
}

.centered {
  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.centeredVertically {
  display: flex;
  align-items: center;
  /* Centers vertically */
  flex-direction: column;
  margin: 0;
  padding: 0;
}




.topBar {
  display: flex;
  justify-content: left;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--c-topBar);
  padding: 2vh 10px;
  height: auto;
  max-height: 1000px;
  min-height: 10vh;
  min-height: 110px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}



.header-button {
  background: none;
  color: var(--c-text2);
  padding: 17px;
  font-weight: bold;
  font-size: 17px;
  margin: 10px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
}

.header-button:hover {
  color: var(--c-accent);
}

.header-button-active {
  color: var(--c-accent);
}

.header-button-active::after {
  content: '';
  position: absolute;
  height: 4px;
  color: var(--c-accent);
  background: var(--c-accent);
  transition: left 1.3s, right 1.3s;
}

.header-button-container {
  position: relative;
  display: flex; /* Ensures the buttons are aligned horizontally */
  white-space: nowrap; /* Prevents buttons from wrapping to the next line */
}


.header-moving-bar {
  position: absolute;
  bottom: 0;
  height: 5px;
  background-color: var(--c-accent);
  transition: left 0.3s, width 0.3s;
}



.section {
  flex: 1;
  /* Equal width for sections 1 and 3 */
  text-align: center;
  display: flex;
  align-items: center;
}

.section-1 {
  justify-content: flex-start;
  /* Push the image to the left */
}

.section-3 {
  justify-content: flex-end;
  /* Push all contents to the right */
}

.push-right {
  margin-left: auto;
  /* Push contents to the right within Section 3 */
}



.banner {
  color: #000;
  color: #000000;
  background-color: var(--c-banner);
  display: flex;
  justify-content: center;
  align-items: center;
}

.introText {
  color: #000;
  background-color: var(--c-introText);
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.introText p {
  width: 60%;
  padding: 40px;
  text-align: center;
}




.uniItem {
  background-color: #fff;
  margin: 20px;
  padding: 8px 30px;
  border: 3px solid #000;
  border-radius: 40px;
  transition: background-color 0.1s ease;

}

.uniItem_1_1 {
  background-color: #fff;
  margin: 20px;
  padding: 8px 30px;
  border: 3.3px solid #000;
  border-radius: 40px;
  transition: background-color 0.1s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 2fr 2fr auto; */
}

.uniItem_1_2 {
  background-color: #fff;
  margin: 20px;
  padding: 8px 30px;
  border: 3.3px solid #000;
  border-radius: 40px;
  transition: background-color 0.1s ease;
  position: 'relative';
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

}


.uniItem_1_1:hover {
  background-color: #f0f0f0;
}

.uniItem_1_2:hover {
  background-color: #f0f0f0;
}

.uniItemDetails {
  grid-column: '2';
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  justify-self: center;
  align-self: center;
  width: 80%;
  margin-bottom: 20px;
  margin-top: 15px;
}



.reviewCount {
  color: #444;
  font-size: small;
}

.badge {
  background-color: #111;
  color: #fff;
  padding: 16px 16px;
  border-Radius: 10px;
  display: 'inline-block'
}

.uniItemMatching {
  background-color: #fff;
  margin: 20px;
  padding: 8px 30px;
  border: 3.3px solid #000;
  border-radius: 40px;
  transition: background-color 0.1s ease;
}




.selectedUni {
  color: #fff;
  background-color: #000;
  display: flex;
  padding: 15px 25px;
  border: 4px solid black;
  border-radius: 15px;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
}



.searchBarContainer{
  padding: 17px 20px;
}

.searchBar {
  /* height: 50px;  */
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  padding: 17px 20px;
  border: 4px solid #000;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
  outline: none;
  transition: border 0.2s ease;
}


.searchBar:focus {
  /* border: 4px solid #30634e; */
}




.buttonSearch {
  background-color: #000000;
  margin: 30px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 40px;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  transition: background-color 0.2s ease;
}

.buttonSearch img {
  max-Width: 30px;
  max-Height: 30px;
}

.buttonSearch:hover {
  background-color: var(--c-accent);
}



.buttonInfo {
  background-color: #444;
  margin: 16px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 13px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  transition: background-color 0.2s ease;

}

.buttonInfo:hover {
  background-color: #aaa;
}


.dropDown {
  margin-Top: 25px;
  position: absolute;
  top: 140%;
  left: 5%;
  right: 5%;
  width: auto;
  z-Index: 30;
  border: 3px solid #000;
  border-Radius: 15px;

}



.evaluationText {
  font-weight: bold;
  font-size: large;
}

.evaluationItem {
  background-color: #fff;
  margin: 20px;
  padding: 40px 60px;
  border: 4px solid #000;
  border-radius: 40px;
  width: auto;
  align-Items: 'center';
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.1s ease;
}


.evaluationItemContent
{
  display: grid;
  grid-template-columns: 15fr 25fr;
  max-width: 2000px;
  gap:  2vw;
  align-Items: center;
  justify-Content: center;
  margin: 15px 7px;
}
.evaluationItemContent h3
{
  text-align: right;
}

.evaluationMarks {
  font-size: medium;
}


.matchingQuestions {
  background-color: #fff;
  margin: 20px;
  padding: 20px 40px;
  border: 3.3px solid #000;
  border-radius: 20px;
  width: auto;
  align-Items: 'center';
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: background-color 0.1s ease;
}


.matchingQuestionMarks {
  font-size: small;
}




.searchContainer {
  width: 35vw;
  min-width: 500px;
  margin-bottom: 50px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: row;
}







.emailInputBar {
  height: 50px;
  width: 40%;
  margin: 5px;
  padding: 2px 20px;
  border-radius: 15px;
  border: none;
  outline: 4px solid black;
  box-sizing: border-box;
  transition: outline 0.2s ease;
}


.emailInputBar:focus {
  /* outline: 4px solid #294ebe; */
}






.buttonSubmit {
  background-color: var(--c-text);
  background: none;
  padding: 22px 20px;
  margin: 15px;
  border: 4px solid black;
  border-radius: 8px;
  transition: color 0.3s ease;
  transition: border-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.buttonSubmit:hover {
  border-color: #008866;
  background-color: #008866;
  border-color: #004f3b;
  background-color: #004f3b;
  color: #fff;
}

.buttonVerifiedSubmit {
  background-color: var(--c-text);
  color: white;
  width: 100%;
  padding: 26px 80px;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.buttonVerifiedSubmit:hover {
  background-color: #074434;
  background-color: #1b764c;
  background-color: #004f3b;
}

.buttonRefresh {
  background-color: #000;
  color: var(--c-text2);
  padding: 24px 48px;
  font-weight: bold;
  font-size: 17px;
  margin: 10px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
  transition: background-color 0.3s ease;
}



.buttonAfterRating {
  background-color: #000;
  color: var(--c-text2);
  padding: 20px 32px;
  font-weight: 400;
  font-size: 14px;
  margin: 10px;
  margin-top: 35px;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
  transition: background-color 0.3s ease;
}


.buttonRefresh:hover {
  background-color: #008866;
  background-color: #004f3b;
}

.buttonAfterRating:hover {
  background-color: #008866;
  background-color: #004f3b;
}




#UniBox {
  background-color: white;
  border: 3px solid black;
  border-radius: 20px;
  height: auto;
  min-width: 400px;
  min-height: 200px;
  padding: 30px;
  margin-bottom: 100px;
  box-shadow: #000;
  display: flex;
  flex-direction: column;
}

#UniBox img {
  margin: 0;
  width: 300px;
  height: auto;
  margin: 10px;
  margin-top: 10px;
  margin-left: 0px;
  justify-self: end;
  border-radius: 10px;
}

#UniBox a {
  margin: 10px;
  margin-left: 10px;

}




.progress-container {
  background-color: #d6d2e8;
  border-radius: 10px;
  margin: 5px 0;
  width: 40%;
}

.progress-bar {
  background-color: rgb(205, 47, 84);
  /* Green color */
  height: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
  line-height: 20px;
  /* Center text vertically */
}


.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}







.emailInputContainer {
  display: flex;
  align-items: center;
}

.emailWarning {
  color: #000;
  color: #fff;
  font-size: small;
  background-color: #ac1350;
  padding: 14px 26px;
  border-radius: 10px;
}




.aboutContainer {
  width: 80%;
  max-width: 1400px;
  margin-top: 100px;

}

.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* background-color: red; */
  margin: 20px 10px;
  border-radius: 20px;
  padding: 10px 40px;
  transition: background-color 0.3s ease;
}

.about-header:hover {
  background-color: var(--c-accent2);
  background-color: #004f3b;
}


.about-header h2 {
  margin: 20px 0;
}

.about-content {
  /* background-color: var(--c-accent2); */
  margin: 80px;
  /* padding: 40px; */
  margin-top: 20px;
  margin-left: 100px;
}

.about-content h3 {
  margin-top: 40px;
}

.about-content p {
  margin-left: 10px;
}

.toggle-icon {
  font-size: 24px;
}






.hidden-badge {
  visibility: hidden;  /* Makes the badge invisible but it still takes up space */
  margin-right: 15px;  /* Ensure it pushes the icon to the right appropriately */
}

.centered-icon {
  display: block;      /* Makes the icon a block element to center it if needed */
  margin: 0 auto;      /* Auto margins for horizontal centering in its container */
  width: 20px;
  height: 20px;
}

.container-relative2 {
  position: relative;  /* Needed only if you have absolutely positioned elements inside */
  display: flex;       /* Using flex to align items nicely and manage spacing */
  align-items: center; /* Vertically center the contents */
  align-self: flex-start;
}






@media (max-width: 1000px) {


  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 170px;
  }

  h3 {
    font-size: 14px;
  }

  p {
    font-size: 11px;
  }








  .tb1 {
    height: 10vh;
    min-height: 80px;
    width: 100%;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    touch-action: none; /* Prevents scrolling interactions */
    align-items: center;
  }

  .tb2 {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: center;
    gap: 10px;
    /* Optional: adds space between grid items */
    width: 100%;
    overflow: visible;
  }


  .bufferItem {
    width: 100%;
    height: 10px;
    grid-column: 3;

  }

  .tb2 img,
  .tb2 .bufferItem {
    justify-self: center;
  }



  .icon {
    max-height: 4vh;
    min-height: 30px;
    width: auto;
    margin: 0px 10px;
    margin-top: 0.7vh;
  }

  .menu {
    max-height: 2vh;
    min-height: 15px;

    width: auto;
    margin: 0px 10px;
    margin-top: 0.3vh;
  }

  .tb3 img {
    max-height: 4vh;
    width: auto;
  }

  .tb3 {
    display: flex;
    /* Enables flexbox layout */
    justify-content: space-between;
    /* Places maximum space between items */
    align-items: center;
    /* Aligns items vertically in the center */
    width: 100%;
    padding: 0 40px;
    height: 100%;
    /* Full width of the parent */
  }


  .tb5 button {
    background: none;
    color: var(--c-accent);
    /* padding: 5px; */
    font-weight: bold;
    font-size: 17px;
    border: none;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    transition: color 0.3s ease;
  }

  .tb5 button:hover {
    color: var(--c-text2);
  }



  .banner {
    color: #000;
    background-color: var(--c-banner);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .introText p {
    width: 80%;
    padding: 20px;
  }












  .mModuleSelectButtons {
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: scroll;
    flex-wrap: unset;
    justify-content: center;
    width: 100%;
    background-color: #000;
    -ms-overflow-style: none;      /* IE and Edge scrollbar */
    scrollbar-width: none;         /* Firefox scrollbar */
  }
  



  .mModuleSelectButton {
    margin: 15px 10px;
    background: none;
    color: white;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 12px;
    border: 2px solid white;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
  }



  .mModuleSelectButton:hover {
    color: var(--c-accent);
    border-color: var(--c-accent);
  }

  .mModuleSelectButton-active {
    color: var(--c-accent);
    border-color: var(--c-accent);

  }


  .slide-in-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100vw;
    /* Full width */
    height: 100vh;
    /* Full height */
    background-color: black;
    /* Background color */
    transition: left 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Distribute space between items */
    align-items: flex-start;
    /* Align items to the start (left) */
    box-sizing: border-box;
    /* Include padding in width/height calculations */
    z-index: 2000;
    /* Ensure it's above other content */
  }

  .slide-in-menu.visible {
    left: 0;
  }

  .slide-in-menu ul {
    list-style: none;
    padding: 0;
  }

  .slide-in-menu li {
    margin: 10px 0;
  }


  .tb1_2 {
    height: 10vh;
    /* 10% of the viewport height */
    width: 100%;
    /* Full width */
    position: relative;
    /* Fixed at the top */
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    align-self: flex-start;
    display: flex;
    /* Enables flexbox layout */
    justify-content: flex-start;
    /* Centers content horizontally */
    align-items: center;
    /* Centers content vertically */
  }

  .about-container {
    padding: 0px;
    left: 0;
    margin-bottom: 40px;
  }



  .header-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    text-align: left;
  }

  .header-button-container button {
    text-align: left;
    font-size: larger;
  }



  .uniItem_1_1 {
    display: flex;
    flex-direction: column;
    border-radius: 17px;
    padding: 10px;
    margin: 20px;
    padding: 10px 20px;
  }


  .uniItem_1_2 {
    border-radius: 17px;
    padding: 10px;
    margin: 20px;
    padding: 10px 20px;
  }

  .uniItemDetails {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }



  .uniItem_1_1 p {
    margin: 0;
  }

  .uniItem_1_1 button {
    margin: 0;
  }

  .uniItem_1_1 h4 {
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .uniItem_1_1 h3 {
    margin: 0;
  }

  .uniItem_1_2 p {
    margin: 0;
  }

  .uniItem_1_2 button {
    margin: 0;
  }

  .uniItem_1_2 h4 {
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .uniItem_1_2 h3 {
    margin: 0;
  }

  .badge {
    color: #fff;
    padding: 14px 14px;
    border-Radius: 10px;
    display: 'inline-block'
  }

  .reviewCount {
    /* background-color: red; */
    font-size: x-small;
    padding-top: 10px;
    align-self: right;
  }

  .filterButton {
    border-radius: 14px;
    background-color: var(--c-introText);
    color: #fff;
    outline: none;
    border: none;
    font-size: small;
    padding: 14px 23px;
    margin-right: 10px;
    display: 'inline-block'
  }

  .rankingOptions {
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    background-color: var(--c-introText);
    color: white;
    padding: 25px 25px;
    margin: 20px 20px;
    margin-top: 20px;
    border-radius: 20px;
  }


  .rankingOptions button {
    background-color: var(--c-accent2);
    color: white;
    padding: 10px 30px;
    margin: 0;
    font-size: smaller;
    border: none;
    outline: none;
    border-radius: 10px;
  }

  .rankingOptions label {
    font-size: smaller;
  }







  .about-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 20px 0px;
    border-radius: 20px;
    padding: 10px 30px;
    transition: background-color 0.3s ease;
    background-color: #004f3b;
    color: #fff;
  }

  .about-header:hover {
    background-color: #000;
  }


  .about-header h2 {
    margin: 20px 0px;
    font-size: small;
  }

  .about-content {
    margin: 40px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .about-content h3 {
    margin-top: 5px;
    font-size: small;
  }

  .about-content p {
    margin-left: 10px;

    font-size: small;
  }

  .toggle-icon {
    font-size: 24px;
  }

  .aboutContainer {
    width: 80%;
    justify-content: center;
    margin-top: 40px;
  }


  .mMatchingOptions {
    background-color: var(--c-introText);
    color: white;
    padding: 20px 30px;
    font-size: smaller;
    border: none;
    outline: none;
    border-radius: 20px;
    width: calc(95% - 40px);
    margin: 0 auto;
    display: block;
  }

  .matchingSettingsText {
    margin: 0 20px;
  }

  .matchingSettingsIcon {
    max-height: 1.5vh;
    width: auto;
    margin: 0px 0px;
    margin-top: 0.1vh;
  }

  .uniItemMatching {
    background-color: #fff;
    margin: 0px;
    margin-top: 20px;
    width: auto;
    border: 3px solid #000;
    border-radius: 40px;
    transition: background-color 0.1s ease;
    border-radius: 17px;
    padding: 0px 20px;
  }

  .uniItemMatching h4 {
    font-size: small;
  }



  .uniItem_1_1 p {
    margin: 0;
  }

  .uniItem_1_1 button {
    margin: 0;
  }

  .uniItem_1_1 h4 {
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .uniItem_1_1 h3 {
    margin: 0;
  }

  .uniItem_1_2 p {
    margin: 0;
  }

  .uniItem_1_2 button {
    margin: 0;
  }

  .uniItem_1_2 h4 {
    margin: 0;
    padding: 0;
    font-size: small;
  }

  .uniItem_1_2 h3 {
    margin: 0;
  }


  .reviewCount2 {
    /* background-color: red; */
    font-size: x-small;
    align-self: right;
    margin-top: 0;
  }



  .matchingOptions {
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    background-color: var(--c-introText);
    color: white;
    padding: 25px 25px;
    margin: 20px 20px;
    margin-top: 20px;
    border-radius: 20px;
  }

  .matchingOptionsTitle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;
  }

  .matchingOptions button {
    background-color: var(--c-accent2);
    color: white;
    padding: 10px 30px;
    margin: 0;
    font-size: smaller;
    border: none;
    outline: none;
    border-radius: 10px;
  }

  .matchingOptions label {
    font-size: smaller;
  }

  .matchingOptions h2 {
    font-size: smaller;
  }





  .matchingQuestions {
    background-color: var(--c-background);
    background-color: #fff;
    margin: 20px 0px;
    border: none;
    border-radius: 20px;
    width: auto;
    align-Items: 'center';
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background-color 0.1s ease;
  }

  .matchingQuestions {
    padding: 20px 30px;
  }

  .matchingQuestions span {
    color: black;
  }

  .matchingQuestions label {
    color: black;
    width: 100%;
  }



  


  .searchContainer {
    width: auto;
    min-width: 0px;
    margin: 0 10px;
    margin-bottom: 50px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: row;
  }




  .buttonSearch {
    background-color: #000000;
    margin: 0px;
    border: none;
    cursor: pointer;
    position: relative;
    border-radius: 29px;
    height: 58px;
    width: 58px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: center;
    padding: 5px;
    border: none;
    transition: background-color 0.2s ease;
  }

  .buttonSearch img {
    max-Width: 20px;
    max-Height: 20px;
  }


  .searchBar {
    /* width: 100%; */
    /* margin: 20px; */
    width: auto;
    width: 100%; 
    border-radius: 15px;
    background-color: #fff;
    padding: 17px 20px;
    border: 4px solid #000;
    box-sizing: border-box;
    outline: none;
    transition: border 0.2s ease;
  }
  


  .dropDown {
    margin-Top: 0px;
    position: absolute;
    /* width: 100%;  */
    top: 100%;
    left: 5vw;
    right: 5vw;
    width: auto;
    z-Index: 30;
    border: 3px solid #000;
    border-Radius: 15px;
  
  }


  .evaluationItemContent
  {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    align-Items: center;
    justify-Content: center;
    margin: 15px 7px;
  }

  .evaluationItemContent h3
  {
    text-align: left;
  }
  .evaluationItem span
  {
    font-size: smaller;
  }


  .evaluationItem {
    background-color: #fff;
    margin-Top: 30px;
    padding: 20px 20px;
    border: 4px solid #000;
    border-radius: 20px;
    width: auto;
    align-Items: 'center';
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  

  .selectedUni {
    display: flex;
    padding: 10px 20px;
    border-radius: 15px;
  }



  

  .buttonSubmit {
    background-color: var(--c-text);
    background: none;
    padding: 15px 20px;
    margin: 15px;
    max-width: 70%;
    border: 3px solid black;
    border-radius: 8px;
    transition: color 0.3s ease;
    transition: border-color 0.3s ease;
    transition: background-color 0.3s ease;
  }


  .buttonVerifiedSubmit {
    background-color: var(--c-text);
    color: white;
    padding: 22px 20px;
    margin: 15px;
    width: 70%;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }



}